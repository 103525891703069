import { useEffect, useState } from "react";
import "./App.css";
import * as nearAPI from "near-api-js";
import styled from "styled-components";

import LoadingOverlay from 'react-loading-overlay';
//import { CookiesProvider } from "react-cookie";
import { useCookies } from "react-cookie";


import rescan from "./rescan.png";


var nettouse = 'testnet';
var baseurl = 'demo.chippedart';
var contractname = "chippedart-dev2.testnet";

var original_title = "";
var original_mediauri = "";
var original_mediahash = "";
var original_description = "";


var document_title = "";
var document_description = "";
var document_uri = "";
var document_hash = "";
var sale_price = "";
var sale_date = "";
var sale_info = "";

var basetoken= "";

var transfer_account = "";


var auth = "";


const Container = styled.div`
  text-align: center;
  max-width: 750px;
  align-items: center;
  margin: auto;
`;

const Header = styled.div`
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: courier;
`;


const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Button = styled.button`
  height: 30px;
  margin: 5px;
  font-family: courier;
`;

function App() {
	

	// eslint-disable-next-line
	const [inpromise, setPleaseWait] = useState(false);
	// eslint-disable-next-line
	const [pleasewaittext, setPleaseWaitText] = useState('');	
	const [docList, setdocList] = useState([['','']]);

	const [salesList, setsalesList] = useState([['','']]);
	const [tagimage, setTagImage] = useState('');
	const [minter, setMinter] = useState('');
	const [owner, setOwner] = useState('');
	const [cookies, setCookie, removeCookie] = useCookies(["keys","state"]);
	const [encryptimage, setEncryptImage] = useState(false); 
	const [isValid, setIsValid] = useState(false);
	
	const handleEncrypt = (encryptflag: String | null ) =>
	{
		console.log(encryptflag)
			if(encryptflag!=null){
				//setShowFileUpload(true);
				if(encryptflag==="Y")
					setEncryptImage(true)
				if(encryptflag==="N" || encryptflag==='')
					setEncryptImage(false)
				//if(encryptflag==='')setShowFileUpload(false);
			}
	}
	

	var tagid = "";
	let fullDocList: any[] = []
	let fullSalesList: any[] = []
	

	// Check whether live or demo
	
	var callingurl = window.location.href;
	if(callingurl.includes(".demo.")){
		nettouse="testnet"
		baseurl = "demo.chippedart";
		contractname = "chippedart-dev2.testnet";
	}
	else{
		nettouse="mainnet"
		baseurl = "chippedart";
		contractname = "chippedart-nft.near"

	}
	
	
	const { connect, keyStores, WalletConnection, utils } = nearAPI;

	
	const config = {
	  networkId: "testnet",
	  keyStore: new keyStores.BrowserLocalStorageKeyStore(),
	  nodeUrl: "https://rpc."+nettouse+".near.org",
	  walletUrl: "https://wallet."+nettouse+".near.org",
	  helperUrl: "https://helper."+nettouse+".near.org",
	  explorerUrl: "https://explorer."+nettouse+".near.org",
	};

	var wallet;
	var gotreturn = false;

	// Get Command Line arguments
	
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
		if(pair[0]==='tagid'){
			tagid = decodeURIComponent(pair[1]);
		}
		if(pair[0]==='transactionHashes'){
			gotreturn = true;
		}

		if(pair[0]==='auth'){
			auth = decodeURIComponent(pair[1]);
		}

    }
	

	

	const [details, setDetails] =
		useState<{
			accountId: string;
			balance: string;
			allowance: string;
			contractName: string;
		}>();
		

	const [isLoggedIn, setIsLoggedIn] = useState(false);

	const mintUpdate = async (keys: string, thestate: object) => {

		// @ts-ignore
		document_title = thestate.document_title
		// @ts-ignore
		document_description = thestate.document_description
		// @ts-ignore
		sale_info = thestate.sale_info
		// @ts-ignore
		sale_price = thestate.sale_price
		// @ts-ignore
		sale_date = thestate.sale_date
		// @ts-ignore
		document_uri = thestate.document_uri
		// @ts-ignore
		document_hash = thestate.document_hash

		
		gotreturn = false;
		
		removeCookie("keys")

		const nearconnect = await connect(config);
		wallet = new WalletConnection(nearconnect,"ChippedArt");
		
		setPleaseWait(true);
		setPleaseWaitText('Updating tag information..');
		
		var urlpost = new URLSearchParams();
		urlpost.append("cmd","registerupdate")
		urlpost.append("uid",tagid)
		urlpost.append("privatekey",keys.split(',')[0])
		urlpost.append("publickey",keys.split(',')[1])
		urlpost.append("account",wallet.getAccountId())
		

		urlpost.append("documenturi",document_uri)
		urlpost.append("documenthash",document_hash)
		urlpost.append("documentname",document_title)
		urlpost.append("documentdescription",document_description)
		urlpost.append("purchasername",sale_info)
		urlpost.append("purchaserprice",sale_price)
		urlpost.append("purchaserdate",sale_date)

		
		urlpost.append("title",original_title);
		urlpost.append("description",original_description);
		urlpost.append("mediauri",original_mediauri);
		urlpost.append("mediahash",original_mediahash);




		var url = "https://api."+baseurl+".com/api.php"
		fetch(url,{
			method:'POST',
			headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			body: urlpost
		})
		.then(res => res.json())
		.then(
			(result) => {
				window.location.href = window.location.pathname+"?tagid="+tagid+"&auth="+auth
			})
			
	}

	const initWallet = async () => {
		
		ping();
		
		console.log(tagid)

		var urlpost = new URLSearchParams();
		urlpost.append("cmd","gettaginfo")
		urlpost.append("uid",tagid)

		var url = "https://api."+baseurl+".com/api.php"
		await fetch(url,{
			method:'POST',
			headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			body: urlpost
		})
		.then(res => res.json())
		.then(
			(result) => {
				
				console.log(result)

				var nftcount=0;
				result.message.forEach((nft: any) => {

					if(nftcount===0){

						original_mediauri = nft.metadata.media
						original_mediahash = nft.metadata.media_hash
						original_title = nft.metadata.title
						original_description = nft.metadata.description
						
						

					}
					setMinter(nft.minter);
					setOwner(nft.owner);
					basetoken = nft.basetoken;
					nftcount++;

					if(nft.reference_contents!==""){
						var s = JSON.parse(nft.reference_contents)
						if(s.documentname!=="" && s.documentdescription!==""){
							fullDocList.push(["<a target='_blank' href='https://api."+baseurl+".com/api.php?cmd=viewdoc&doc="+s.document+"&uid="+tagid+"&auth="+auth+"&base="+s.documentname+"' target='_new'>"+s.documentname+"</a>",s.documentdescription])
						}
						if(s.purchaser_name!=="" || s.purchase_price!==""){
							fullSalesList.push([s.purchase_price,s.purchase_date,s.purchaser_name])
						}
					}

				}
				
				
					
					
				);
				
			}
		)
		
		setdocList(fullDocList)
		setsalesList(fullSalesList)


		setTagImage("https://api."+baseurl+".com/api.php?cmd=viewdoc&uid="+tagid+"&auth="+auth+"&doc="+original_mediauri)
		
		const near = await connect(config);
		wallet = new WalletConnection(near,"ChippedArt");
		
		if(wallet.isSignedIn()){

			const account = await wallet.account();
			var details = {accountId:"",balance:"",allowance:"",contractName:""};
			if(details!==undefined){
				details.accountId = wallet.getAccountId();
				details.balance = utils.format.formatNearAmount((await account.getAccountBalance()).available,2);
				details.allowance = "";
				details.contractName = "";
				setDetails(details);

			}
			setIsLoggedIn(true);

		}else{

			setIsLoggedIn(false);
		}
		
		/*window.setInterval(function(){
			ping();
		},10000);
		*/
		
		if(cookies.keys && gotreturn){

			mintUpdate(cookies.keys,cookies.state);
		}
		



	};
	
	const ping = async () => {
		console.log("Auth Code="+auth)
		var urlpost = new URLSearchParams();
		urlpost.append("cmd","checkauthcode")
		urlpost.append("dbuid",tagid)
		urlpost.append("auth",auth)

		var url = "https://api."+baseurl+".com/api.php"
		await fetch(url,{
			method:'POST',
			headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			body: urlpost
		})
		.then(res => res.json())
		.then(
			(result) => {
				console.log(result);
				if(result.data==="OK")setIsValid(true);
			}
			)
	};
	


	const handleConnect = async (shouldRequest: boolean) => {
		
		const near = await connect(config);
		wallet = new WalletConnection(near,"ChippedArt");
	
		if(wallet.isSignedIn()){

		}else{

			wallet.requestSignIn("","ChippedArt");
		}
	};
  
	const handleDisconnect = async () => {
		 
		const near = await connect(config);
		const wallet = new WalletConnection(near,"ChippedArt");

		await wallet.signOut();
		window.location.reload();
	};
	
	const handleNewDocTitle = (documentTitle: string | null) =>
    {
		if(!documentTitle)return;
		document_title = documentTitle;
		
    }

	const handleNewDocDescription = (documentDescription: string | null) =>
    {
		if(!documentDescription)return;
		document_description = documentDescription;
		
    }

	const handleNewSalePrice = (saleprice: string | null) =>
    {
		if(!saleprice)return;
		sale_price = saleprice
		
    }
	
	const handleNewSaleDate = (saledate: string | null) =>
    {
		if(!saledate)return;
		sale_date = saledate
		
    }

	const handleNewSaleDetails = (saledetails: string | null) =>
    {
		if(!saledetails)return;
		sale_info = saledetails
		
    }

	const handleTransferAccount = (transferaccount: string | null) =>
    {
		if(!transferaccount)return;
		transfer_account = transferaccount
		
    }

	const handleTransfer = () =>
    {
		console.log('transfer to '+transfer_account);
		
		var json = `{
		  "jsonrpc": "2.0",
		  "id": "dontcare",
		  "method": "query",
		  "params": {
			"request_type": "view_account",
			"finality": "final",
			"account_id": "`+transfer_account+`"
		  }
		}`
			
		//var rpc = "https://rpc.testnet.near.org";#
		var rpc = "https://rpc."+nettouse+".near.org"
			
		fetch(rpc,
		{
			headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
			},
			method: "POST",
			body: json
		})
		.then(res => res.json())
		.then((result) => {
			//console.log(result)
			if(result.error===undefined){
				console.log('good account');
				doTransfer();
				

				
			}else{
				console.log('bad account')
			}
		}
		);
		//console.log(json)
		
    }	
	
	const doTransfer = async () =>
	{
		const near = await connect(config);
		var wallet = new WalletConnection(near,"ChippedArt");
				
		// @ts-ignore
		const contract = new nearAPI.Contract(
			wallet.account(), // the account object that is connecting
			contractname,
			{
				// name of contract you're connecting to
				viewMethods: ["nft_token"], // view methods do not change state but usually return a value
				changeMethods: ["nft_mint","nft_transfer"], // change methods modify state
			}
		);
		
		// @ts-ignore
		contract.nft_transfer(
			{
				receiver_id: transfer_account,
				token_id:basetoken,
				
			},80000000000000,1
		
		);		
		
		
		
	}
	
	
	const handleUpdate = async () =>
	{

		const newKeyPair = utils.KeyPair.fromRandom('ed25519')
		const privatekey = newKeyPair.toString();
		const public_key = newKeyPair.getPublicKey()
		const publickey = public_key.toString();


		const nearconnect = await connect(config);
		wallet = new WalletConnection(nearconnect,"ChippedArt");		

		const account = await wallet.account();
		
		const updateinfo = {document_title: document_title,document_description: document_description,sale_price: sale_price,sale_date: sale_date,sale_info: sale_info, document_uri: document_uri, document_hash: document_hash, encrypt: encryptimage}
		
	
		let d = new Date();
		d.setTime(d.getTime() + (60*1000));

		setCookie("keys", privatekey+","+publickey, {path: "/", expires: d});
		setCookie("state",JSON.stringify(updateinfo),{path: "/", expires: d});

		await account.addKey(publickey);
		
		// Will not return to anything after here!
		
		
	}
	
	const handleFile = (selectorFiles: FileList | null) =>
    {

		if(!selectorFiles)return;
		setPleaseWaitText('Please Wait. Uploading your image');
		setPleaseWait(true);
		var encrypt = "Y";
		if(!encryptimage)encrypt="N";
		var urlpost = new URLSearchParams();
		urlpost.append("cmd","uploadfile")
		urlpost.append("dbuid",tagid);
		urlpost.append("encrypt",encrypt);

		
		var reader = new FileReader();
			
		reader.readAsDataURL(selectorFiles[0]);

		reader.onload = function () {
			if(reader.result!==null && reader.result!==undefined){
				var infile = ''
				infile = reader.result+'';

				urlpost.append("infile",infile)

				var url = "https://api."+baseurl+".com/api.php"
				fetch(url,{
					method:'POST',
					headers: {'Content-Type': 'application/x-www-form-urlencoded'},
					body: urlpost
				})
				.then(res => res.json())
				.then((resp) => {
	
					document_uri = resp.data.uri;
					document_hash = resp.data.hash;
					setPleaseWait(false);

				})
			}		 
		};
    }
	
	useEffect(() => {
		initWallet();
	}, []);  // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<LoadingOverlay
			active={inpromise}
			spinner
			text={pleasewaittext}
		>

			<Container>
		
				<Header>
				{isValid && (
					<>
					<br/>
					<img src={tagimage} alt='logo' width={200}/>
					{isLoggedIn && details && owner===details.accountId && (
					<>
					<br/>
					<label>Transfer To:</label>
					<input onChange={ (e) => handleTransferAccount(e.target.value) }  />
					<Button onClick={() => handleTransfer()}>Transfer</Button>
					</>
					)}
					<br/>
					
					<label><b>Document List</b></label><br/>
					<table className='price-table' width={"98%"}>
						<thead>
							<th>Title</th><th>Description</th>
						</thead>
						<tbody>
							{[...docList].map((elementInArray, index) => {
								return (
									<tr>
										<td dangerouslySetInnerHTML={{
    __html: elementInArray[0]
  }}></td>
										<td>{elementInArray[1]}</td>
									</tr>
								);
							})}
							{isLoggedIn && details && (owner===details.accountId || minter===details.accountId) && (
							<>
							<tr><td colSpan={2}><b>Upload New Document</b></td></tr>
							<tr>
								<td colSpan={2}>
									<table width={"100%"}>
										<tr><td>Title</td><td><input onChange={ (e) => handleNewDocTitle(e.target.value) }  /></td></tr>
										<tr><td>Description</td><td><input onChange={ (e) => handleNewDocDescription(e.target.value) }  /></td></tr>
									</table>
								</td>
							</tr>
							<tr>
								<td colSpan={2}>
									<table>
										<tbody>
											<tr>
												<td>Encrypt uploaded files?</td>
												<td>
													<select onChange={ (e) => handleEncrypt(e.target.value) }>
														<option value=''>[Select]</option>
														<option value='N'>No</option>
														<option value='Y'>Yes</option>
													</select>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
							<tr>
								<td colSpan={2}>
									<input type="file" onChange={ (e) => handleFile(e.target.files) } />
								</td>
							</tr>
							</>
							)}
						</tbody>
					</table><br/>

					<label><b>Sales Record</b></label><br/>
					<table className='price-table' width={"98%"}>
						<thead>
							<th>Price/Date</th><th>Details</th>
						</thead>
						<tbody>
							{[...salesList].map((elementInArray, index) => {
							return (
								<tr>
									<td>{elementInArray[0]}<br/>{elementInArray[1]}</td>
									<td>{elementInArray[2]}</td>
								</tr>
								);
							})}
							{isLoggedIn && details && (owner===details.accountId || minter===details.accountId) && (
							<>
							<tr><td colSpan={2}><b>Upload New Sale Details</b></td></tr>
							<tr>
								<td colSpan={2}>
									<table width={"100%"}>
										<tr><td>Price</td><td><input onChange={ (e) => handleNewSalePrice(e.target.value) } /></td></tr>
										<tr><td>Date</td><td><input onChange={ (e) => handleNewSaleDate(e.target.value) }  /></td></tr>
										<tr><td>Details</td><td><input onChange={ (e) => handleNewSaleDetails(e.target.value) }  /></td></tr>
									</table>
								</td>
								
							</tr>

							</>
							)}
						</tbody>
					</table><br/>
					{isLoggedIn && details && (owner===details.accountId || minter===details.accountId) && (
					<>
					<Button onClick={() => handleUpdate()}>Update Tag Details</Button><br/>
					</>
					)}
						
					<ButtonsContainer>
						{!isLoggedIn &&(
							<Button onClick={() => handleConnect(true)}>Login to Edit</Button>
						)}
						{isLoggedIn && details && (
							<table>
								<tbody>
									<tr><td><Button onClick={() => handleDisconnect()}>Logout</Button></td></tr>
									<tr><td>Account: {details.accountId} ({details.balance}N)</td></tr>
								</tbody>
							</table>
						)}
					</ButtonsContainer>
					</>
				)}
				{!isValid && (
					<>
					<br/><br/>
					<label>Please Rescan</label>
					<img src={rescan} width={"50%"} height={"50%"} alt={"rescan"}/>
					</>
				)}
				</Header>	  
			</Container>
		</LoadingOverlay>
	);
}

export default App;
